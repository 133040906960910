export const alert = {
  namespaced: true,
  state: {
    type: null,
    message: null,
    dismissSecs: 8,
    dismissCountDown: 0
  },
  actions: {
    success({ commit }, message) {
      commit('success', message);
    },
    error({ commit }, message) {
      commit('error', message);
    },
    warn({ commit }, message) {
      commit('warn', message);
    },
    clear({ commit }, message) {
      commit('success', message);
    }
  },
  mutations: {
    success(state, message) {
      state.type = 'success';
      state.message = message;
      state.dismissSecs = 5;
      state.dismissCountDown = state.dismissSecs;
    },
    error(state, message) {
      state.type = 'danger';
      state.message = message;
      state.dismissSecs = 10;
      state.dismissCountDown = state.dismissSecs;
    },
    warn(state, message) {
      state.type = 'warning';
      state.message = message;
      state.dismissSecs = 10;
      state.dismissCountDown = state.dismissSecs;
    },
    clear(state) {
      state.type = null;
      state.message = null;
      state.dismissSecs = 0;
      state.dismissCountDown = state.dismissSecs;
    }
  }
}
