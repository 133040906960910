import router from "@/router";

export function authHeader() {
  // return authorization header with jwt user
  let token = JSON.parse(localStorage.getItem('token'));

  if (token) {
    return { 'Authorization': 'Bearer ' + token };
  } else {
    return {};
  }
}

export function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('token');
        if (router.currentRoute.name !== "login") {
          location.reload(true);
        }
      }


      const error = (data && (data.message || data['hydra:description'])) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });

}
