//import config from 'config';
import {authHeader} from '../_helpers';
import {handleResponse} from '../_helpers';


export const userService = {
  login,
  logout,
  getProfiles,
  getUserData,
  getLocations,
  setProfileSession,
  logoutProfileSession,
  getUserCustomers,
  getUserLocations,
  refreshToken
};

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({email : email, password: password})
  };

  return fetch(process.env.VUE_APP_API_SERVER+`/authentication_token`, requestOptions)
      .then(handleResponse)
      .then(data => {
        // login successful if there's a jwt user in the response
        if (data.token) {
          // store user details and jwt user in local storage to keep user logged in between page refreshes
          localStorage.setItem('token', JSON.stringify(data.token));
          localStorage.setItem('refreshtoken', JSON.stringify(data.refresh_token));
        }

        return data;
      });
}

function refreshToken(token) {
  const header = authHeader();
  header['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
  const requestOptions = {
    method: 'POST',
    headers: header,
    body: 'refresh_token='+token
  };

  return fetch(process.env.VUE_APP_API_SERVER+`/api/token/refresh`, requestOptions)
      .then(handleResponse)
      .then(data => {
        // login successful if there's a jwt user in the response
        if (data.token) {
          // store user details and jwt user in local storage to keep user logged in between page refreshes
          localStorage.setItem('token', JSON.stringify(data.token));
          localStorage.setItem('refreshtoken', JSON.stringify(data.refresh_token));
        }

        return data;
      });
}

function getUserData() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(process.env.VUE_APP_API_SERVER+`/api/currentuser`, requestOptions)
      .then(handleResponse)
      .then(data => {
        // login successful if there's a jwt user in the response
        return data;
      });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('token');
  localStorage.removeItem('refreshtoken');
}


function getUserCustomers() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(process.env.VUE_APP_API_SERVER+`/api/user_customers?pagination=false`, requestOptions).then(handleResponse);
}

function getUserLocations() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(process.env.VUE_APP_API_SERVER+`/api/user_locations?pagination=false`, requestOptions).then(handleResponse);
}



// ===> für Profile nutzen
function getProfiles() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(process.env.VUE_APP_API_SERVER+`/api/profiles?pagination=false`, requestOptions).then(handleResponse);
}
// ===> für Profile nutzen
function getLocations() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(process.env.VUE_APP_API_SERVER+`/api/locations?pagination=false`, requestOptions).then(handleResponse);
}


function setProfileSession(id) {
  const header = authHeader();
  header['Content-Type'] = 'application/json';
  const requestOptions = {
    method: 'GET',
    headers: header,
  };
  return fetch(process.env.VUE_APP_API_SERVER + `/api/currentprofile/set/`+id, requestOptions)
      .then(handleResponse)
      .then(data => {
        return data;
      });
}

function logoutProfileSession(id) {

  const header = authHeader();
  header['Content-Type'] = 'application/json';
  const requestOptions = {
    method: 'GET',
    headers: header,
  };
  return fetch(process.env.VUE_APP_API_SERVER + `/api/currentprofile/logout/`+id, requestOptions)
      .then(handleResponse)
      .then(data => {
        return data;
      });

}

