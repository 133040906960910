<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name:"content",
    methods:{

    }
  }
</script>

<style scoped>


</style>