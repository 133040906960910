import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { authHeader } from './_helpers'
import { handleResponse } from './_helpers'
import { store } from './_store';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import moment from 'moment'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/app.scss'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false

Vue.mixin({
  methods:{
    authHeader() {
      return authHeader();
    },
    handleResponse(response) {
      return handleResponse(response);
    },

  }})


Vue.filter('formatDateFromFormatToFormat', function(from, to, value) {
  if (value && from && to) {
    return moment(value,from).format(to)
  }
});


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
