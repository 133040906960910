import Vue from 'vue'
import Router from 'vue-router'
import { store } from '@/_store'
import Login from '@/components/login'
import Check from  '@/components/check'
import ContentWrapper from '@/components/ContentWrapper'


Vue.use(Router);

const router = new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: '/service/check',
      component: ContentWrapper,
      children: [
        {
          path: '/service/check',
          component: Check,
          meta: {requiresAuth: true, title: 'Spielercheck'}
        }
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: ContentWrapper,
      children: [
        {
          path: '/login',
          name: 'login',
          component: Login
        }
      ]
    }]});



router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (loggedIn) {
      if(!store.getters['authentication/isAuthenticated']) {
        store.dispatch('loading/isLoading');
        store.dispatch('authentication/onRefresh').then(()=>{store.dispatch('loading/loadingIsFinished');next();});
      } else {
        next();
      }
    } else {
      next('/login')
    }
  } else {
    next(); // make sure to always call next()!
  }
});



export default router;
